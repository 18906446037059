<template lang="">
    <div>
        
        <div>
            <v-container class="text-center mb-5">
              <div class="text-h3 font-weight-black text-center">Síguenos en nuestras redes</div>
              <div class="d-flex font-weight-black justify-center py-10 flex-wrap">
                <v-card class="pa-5 ma-1" color="#404eed" dark flat rounded="0" href="https://discord.gg/y7RSw3WE66" target="_blank">
                  <font-awesome-icon :icon="['fab', 'discord']" class="mr-2"/>Discord
                </v-card>
                <v-card class="pa-5 ma-1" color="#3b5998" dark flat rounded="0" href="https://www.facebook.com/fiudec" target="_blank">
                  <font-awesome-icon :icon="['fab', 'facebook']" class="mr-2"/>Facebook
                </v-card>
                <v-card class="pa-5 ma-1" color="#d10869" dark flat rounded="0" href="https://www.instagram.com/fiudec/?hl=es-la" target="_blank">
                  <font-awesome-icon :icon="['fab', 'instagram']" class="mr-2"/>Instagram
                </v-card>
                <v-card class="pa-5 ma-1" color="#c4302b" dark flat rounded="0" href="https://www.youtube.com/c/FacultaddeIngenier%C3%ADaUdeC" target="_blank">
                  <font-awesome-icon :icon="['fab', 'youtube']" class="mr-2"/>Youtube
                </v-card>
                <v-card class="pa-5 ma-1" color="#00acee" dark flat rounded="0" href="https://twitter.com/fiudec" target="_blank">
                  <font-awesome-icon :icon="['fab', 'twitter']" class="mr-2"/>Twitter
                </v-card>
                <v-card class="pa-5 ma-1" color="#0e76a8" dark flat rounded="0" href="https://www.linkedin.com/company/fiudec/" target="_blank">
                  <font-awesome-icon :icon="['fab', 'linkedin']" class="mr-2"/>Linkedin
                </v-card>
              </div>
              <v-btn color="green accent-3" rounded outlined v-if="preLanzamiento">
                Contáctanos por Whatsapp
                <font-awesome-icon :icon="['fab', 'whatsapp']" class="ml-3" size="2x"/>
              </v-btn>
            </v-container>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
</style>
<template>
  <v-app>
    <!-- Navegación principal |INICIO| -->
    <main-header></main-header>
    <!-- Navegación principal |FIN| -->

    <v-main>
      <!-- Contenido principal |INICIO| -->
      <router-view />
      <!-- Contenido principal |FIN| -->
    </v-main>

    <main-footer></main-footer>
  </v-app>
</template>

<script>
import MainFooter from "./components/nav/MainFooter.vue";
import MainHeader from "./components/nav/MainHeader.vue";
export default {
  components: { MainHeader, MainFooter },
  name: "App",

  data: () => ({
    //
  }),
  mounted() {
    if (this.preLanzamiento || this.lanzamiento) {
      this.verificarSesion();
    }
  },
  watch: {
    has_estadoTestVocacional() {},
    resultadoTestVocacional() {},
    isUserLoggedIn() {},
    has_resultadoTestVocacional(n) {
      // ? Si el resultado del test existe
      if(n){
        this.consultarRespuestaTest();
      }
    },
    has_datosUsuario(n) {
      if (n) {
        // ? Consulta el estado del resultado del test vocacional
        this.consultarEstadoTest();
      }
    },
  },
};
</script>

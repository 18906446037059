<template lang="">
  <vac
    :startTime="new Date().getTime()"
    :end-time="new Date('2021-09-06T18:00:00').getTime()"
  >
  <template v-slot:process="{ timeObj }">
  <div>
    <v-responsive
      min-height="600px"
      content-class="d-flex flex-column justify-center blue lighten-5"
    >
      <div class="py-12">
        <div class="d-flex justify-center">
          <v-img
            alt="FI Logo"
            class="shrink mr-2"
            contain
            position="center center"
            :src="require('../../assets/logohomefi.png')"
            transition="scale-transition"
            width="300"
          />
        </div>
        <div
          class="
            text-center text-h4
            font-weight-medium
            blue-grey--text
            text--darken-3
          "
        >
          Apertura instrumento vocacional
        </div>
        <div class="text-center text-overline">{{preLanzamientoLabel}}</div>
            <div class="text-center">
              <span class="mx-2">
                <span class="text-h2 blue-grey--text text--darken-1">{{
                  timeObj.d
                }}</span>
                <span class="text-overline">días</span>
              </span>
              <span class="mx-2">
                <span class="text-h2 blue-grey--text text--darken-1">{{
                  timeObj.h
                }}</span>
                <span class="text-overline">horas</span>
              </span>
              <span class="mx-2">
                <span class="text-h2 blue-grey--text text--darken-1">{{
                  timeObj.m
                }}</span>
                <span class="text-overline">min</span>
              </span>
              <div class="d-inline-block ml-3">
                <div
                  class="d-inline-block text-center"
                  style="min-width: 38px"
                  v-for="(numero, index) in timeObj.s.split('')"
                  :key="index"
                >
                  <span class="text-h2 blue-grey--text text--lighten-3">{{
                    numero
                  }}</span>
                </div>
              </div>
            </div>
              </div>
            </v-responsive>
    </div>
    </template>
    <!-- <template v-slot:finish><span>Done!</span></template> -->
    </vac>
</template>
<script>
export default {};
</script>
<style lang="">
</style>
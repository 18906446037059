import Vue from 'vue'
import Vuex from 'vuex'

import { storeUser } from './_user'
import { storeAcceso } from './_acceso'
import { storeOpciones } from './_opciones'
import { storeFI } from './_fi'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: storeUser,
    acceso: storeAcceso,
    opciones: storeOpciones,
    fi: storeFI,
  }
})

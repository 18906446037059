import { mapGetters, mapMutations } from "vuex";
export const storeUser = {
    state: {
        userLoggedIn: false,
        userData: {
            username: false,
            name: false,
            email: false,
        },
        userMeta: {
            has_testVocacional: false,
        },
    },
    getters: {
        is_userLoggedIn(state) {
            return state.userLoggedIn;
        },
        get_userData(state) {
            return state.userData;
        },
    },
    mutations: {
        logIn(state) {
            state.userLoggedIn = true
        }
    },
    actions: {

    },
    modules: {

    }
}

export const mixinUser = {
    computed: {
        ...mapGetters([
            'is_userLoggedIn',
            'get_userData',
        ]),
    },
    methods: {
        ...mapMutations([
            'logIn',
        ])
    }
}


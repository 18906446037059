<template>
  <div>
    <v-card
      id="test-vocacional"
      :class="{
        'white--text pa-7 rounded-lg elevation-4': true,
        'red ': !has_resultadoLoadedTestVocacional,
        'teal lighten-1': has_resultadoLoadedTestVocacional,
      }"
      to="/test-vocacional"
      :disabled="!preLanzamiento"
    >
      <div class="text-h3 font-weight-black text-uppercase mb-3">
        🤝 {{ has_resultadoLoadedTestVocacional ? "Resultado " : "" }}Test
        vocacional
      </div>
      <div class="text-subtitle-1" v-if="!has_resultadoLoadedTestVocacional">
        ¡En menos de 7 minutos, descubre cuál es la carrera con la que haces
        match en Ingeniería!
      </div>
      <div class="text-subtitle-1" v-if="has_resultadoLoadedTestVocacional">
        ¡Revisa el resultado de cuál es la carrera con la que haces match en
        Ingeniería!
      </div>
      <div class="mt-3">
        <v-chip dark label outlined small v-if="!preLanzamiento"
          >06/09/2021 18:00</v-chip
        >
      </div>
    </v-card>

    <v-card
      id="explora-tu-vocacion"
      class="white--text pa-7 rounded-lg elevation-4 mt-5"
      to="/explora-tu-vocacion"
      :disabled="!lanzamiento"
    >
      <div class="text-h3 font-weight-black text-uppercase mb-3">
        🎡 Explora tu vocación
      </div>
      <div class="text-subtitle-1">
        Descubre todo acerca de las carreras de la Facultad de Ingeniería
      </div>
      <div class="mt-3">
        <v-chip dark label outlined small v-if="!lanzamiento">{{
          lanzamientoLabel
        }}</v-chip>
      </div>
    </v-card>

    <div id="grilla-secciones">
      <v-card
        v-for="(seccion, index) in seccionesFiltradas"
        :key="index"
        :class="['pa-6', seccion.color]"
        outlined
        light
        :disabled="!lanzamiento"
        :to="seccion.to"
        class="d-flex justify-space-between"
      >
        <div class="d-flex flex-column justify-space-between">
          <div>
            <div class="text-h4 font-weight-black text-uppercase">
              {{ seccion.name }}
            </div>
            <div class="text-subtitle-1">{{ seccion.desc }}</div>
          </div>

          <div class="mt-3">
            <v-chip label outlined small v-if="!lanzamiento">{{
              lanzamientoLabel
            }}</v-chip>
          </div>
        </div>
        <div>
          <font-awesome-icon
            :icon="['fad', seccion.icon]"
            class="ml-4"
            size="5x"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    v: {
      type: String,
      default: "default",
      required: false,
      description: "Versión de la vista",
    },
  },
  data() {
    return {
      secciones: [
        {
          name: "Mundo Ingeniería",
          desc: "Por qué la Ingeniería es un mundo, ¡Descúbrelo junto a nosotros!",
          color: "blue--text text--darken-4",
          to: "/mundo-ingenieria",
          show: ["default"],
          icon: "solar-system",
        },
        {
          name: "Actividades y eventos",
          desc: "Revisa nuestro calendario de eventos y conoce las actividades de las que podrás ser parte. ¡Disfruta de un panorama entretenido!",
          color: "orange--text text--darken-4",
          to: "/actividades-y-eventos",
          show: ["default"],
          icon: "podium-star",
        },
        {
          name: "Cursos y talleres",
          desc: "Disponemos una amplia gama de cursos enfocados en cuatro ejes: nivelación para el primer año, apoyo psicoeducativo, inglés e innovación y emprendimiento. ¡Revisa la oferta actual de cursos aquí!",
          color: "light-blue--text text--darken-2",
          to: "/cursos-y-talleres",
          show: ["default"],
          icon: "users-class",
        },
        {
          name: "Comunidad FI",
          desc: "¿Quieres hacer preguntas, ayudar a otros usuarios o sencillamente compartir temas de interés? ¡Únete a nuestra Comunidad FI en Discord y redes sociales! ¡Bienvenido!",
          color: "yellow--text text--darken-3",
          to: "/comunidad-fi",
          show: ["default"],
          icon: "ball-pile",
        },
        {
          name: "Guía mechona",
          desc: "Lorem ipsum dolor sit amet , consectetur adipiscing elit. Curabitur sit amet",
          color: "blue--text text--darken-3",
          to: "/guia-mechona",
          show: ["mundo-ingenieria"],
          icon: "book-spells",
        },
        {
          name: "Descubre la FI",
          desc: "Lorem ipsum dolor sit amet , consectetur adipiscing elit. Curabitur sit amet",
          color: "orange--text text--darken-3",
          to: "/descubre-la-fi",
          show: ["mundo-ingenieria"],
          icon: "map",
        },
      ],
    };
  },
  computed: {
    seccionesFiltradas() {
      let secciones = this.secciones;
      secciones = secciones.filter((el) => {
        return el.show.includes(this.$props.v);
      });
      return secciones;
    },
  },
};
</script>
<style lang="scss">
#explora-tu-vocacion {
  background: #9f1b61;
}

#grilla-secciones {
  padding: 20px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
</style>
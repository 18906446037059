<template>
  <v-app-bar app color="grey lighten-5" elevation="0" light>
    <v-responsive content-class="d-flex justify-center">
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="FI Logo"
            class="shrink mr-2"
            contain
            :src="require('../../assets/logoficolor.jpg')"
            transition="scale-transition"
            width="40"
          />
        </router-link>
      </div>
      <v-spacer v-if="preLanzamiento"></v-spacer>
      <div v-if="preLanzamiento">
        <v-btn
          href="https://discord.gg/y7RSw3WE66"
          target="_blank"
          color="#404eed"
          dark
          text
        >
          <span class="">
            <font-awesome-icon :icon="['fab', 'discord']" class="mr-2" />Discord
            FI</span
          >
        </v-btn>
        <v-btn
          to="/mundo-ingenieria"
          v-if="lanzamiento"
          color="blue-grey darken-2"
          dark
          text
        >
          <span class="">Mundo Ingeniería</span>
        </v-btn>
        <v-btn
          to="/cursos-y-talleres"
          v-if="lanzamiento"
          color="blue-grey darken-2"
          dark
          text
        >
          <span class="">Cursos</span>
        </v-btn>
        <v-btn
          href="/actividades-y-eventos"
          color="blue-grey darken-2"
          v-if="lanzamiento"
          dark
          text
        >
          <span class="">Eventos</span>
        </v-btn>

        <v-btn
          color="#9f1b61"
          dark
          elevation="0"
          outlined
          to="iniciar-sesion"
          v-if="!isUserLoggedIn"
        >
          <span class="">Iniciar sesión</span>
          <!-- <v-icon class="ml-2">mdi-open-in-new</v-icon> -->
        </v-btn>

        <!--  -->
        <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
          nudge-top="-5"
          v-if="isUserLoggedIn && has_datosUsuario"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="blue accent-4" size="40">
                <span class="white--text text-h6">{{
                  datosUsuario.data.meta.first_name.slice(0, 1) +
                  datosUsuario.data.meta.last_name.slice(0, 1)
                }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card class="" flat>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="blue accent-4">
                  <span class="white--text text-h5">{{
                    datosUsuario.data.meta.first_name.slice(0, 1) +
                    datosUsuario.data.meta.last_name.slice(0, 1)
                  }}</span>
                </v-avatar>
                <div class="text-body-1 font-weight-black mt-1">
                  {{ datosUsuario.data.display_name }}
                </div>
                <p class="text-caption mt-1">
                  {{ datosUsuario.data.user_email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text to="/perfil">Perfil</v-btn>
                <!-- <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text to="/editar-perfil"
                  >Editar cuenta</v-btn
                > -->
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click="cerrarSesion">
                  Cerrar sesión
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>

        <!--  -->
      </div>
    </v-responsive>
  </v-app-bar>
</template>
<script>
export default {};
</script>
<style lang="scss">
</style>
<template>
  <div>
    <!-- <pre>{{ datosUsuario }}</pre> -->
    <timer-home v-if="!preLanzamiento"></timer-home>
    <v-container>
      <v-img
        content-class="d-md-flex align-center justify-lg-space-between py-10"
        min-height="500px"
      >
        <!-- JUMBO HOME |INICIO| -->
        <div class="flex-grow-1" id="contenedor-mensaje">
          <!-- Título general inicio -->
          <div class="text-h2" id="nombre-home">Experiencia ingeniería</div>
          <!-- Título general inicio -->
          <!-- Texto bienvenida -->
          <div class="my-4">
            <span class="font-weight-bold"
              >¡Descubre la nueva forma de experimentar la ingeniería UdeC!
            </span>
            <br />
            <span
              >A través de esta plataforma podrás prepararte para tomar las
              mejores decisiones para tu futuro.</span
            >
            <div v-if="!is_userLoggedIn && preLanzamiento">
              <span>¡Regístrate y descubre todo lo que tenemos para ti!</span>
            </div>
          </div>
          <!-- Texto bienvenida -->
          <!-- Call-to-action registro -->
          <div v-if="!isUserLoggedIn">
            <v-btn
              color="orange darken-3"
              pill
              elevation="0"
              outlined
              dark
              class=""
              v-if="preLanzamiento"
              to="/registro"
              >Quiero registrarme
              <font-awesome-icon
                :icon="['fad', 'thumbs-up']"
                class="ml-2"
                size="1x"
              />
            </v-btn>
          </div>
          <!-- Call-to-action registro -->
        </div>
        <!-- JUMBO HOME |FIN| -->
        <div class="flex-grow-1 d-flex justify-end" id="contenedor-video">
          <!-- Video -->
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/j0M7YgA2mV0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <!-- Video -->
        </div>
      </v-img>
      <derivaciones-home></derivaciones-home>
      <div></div>
    </v-container>
  </div>
</template>

<script>
import DerivacionesHome from "../components/info/DerivacionesHome.vue";
import TimerHome from "../components/info/TimerHome.vue";
export default {
  name: "Home",
  components: { DerivacionesHome, TimerHome },
};
</script>
<style>
</style>

import { mapGetters, mapMutations } from "vuex";
export const storeOpciones = {
  state: {
    debug: true,
    preLanzamiento: true,
    lanzamiento: false,
    preLanzamientoLabel: '06/09/2021',
    lanzamientoLabel: 'Octubre 2021',
  },
  getters: {
    preLanzamiento(state) {
      return state.preLanzamiento
    },
    lanzamiento(state) {
      return state.lanzamiento
    },
    lanzamientoLabel(state) {
      return state.lanzamientoLabel
    },
    preLanzamientoLabel(state) {
      return state.preLanzamientoLabel
    },
  },
  mutations: {

  },
  actions: {

  },
  modules: {

  }
}

export const mixinOpciones = {
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'preLanzamiento',
      'lanzamiento',
      'lanzamientoLabel',
      'preLanzamientoLabel',
    ]),
  },
  methods: {
    ...mapMutations([

    ])
  }
}
/**
 * ? ACCESO
 * Stores y mixins globales para interacturar con sistema de acceso
 * Inicio de sesión
 * Registro
 * Estado de conexión
 */
import { mapGetters, mapMutations } from "vuex";

export const storeAcceso = {
  state: {
    isUserLoggedIn: false,
    verificandoSesion: false,
    has_datosUsuario: false,
    userToken: false,
    datosUsuario: {

    }
  },
  getters: {
    isUserLoggedIn(state) {
      return state.isUserLoggedIn
    },
    datosUsuario(state) {
      return state.datosUsuario
    },
    has_datosUsuario(state) {
      return state.has_datosUsuario
    },
    verificandoSesion(state) {
      return state.verificandoSesion
    }
  },
  mutations: {
    activarSesion(state) {
      state.isUserLoggedIn = true;
    },
    updateUserData(state, { data }) {
      state.datosUsuario = data;
    },
    activarUserData(state) {
      state.has_datosUsuario = true;
    },
    cerrarSesion(state) {
      state.isUserLoggedIn = false;
      state.has_datosUsuario = false;
    },
    si_verificandoSesion(state) {
      state.verificandoSesion = true;
    },
    no_verificandoSesion(state) {
      state.verificandoSesion = false;
    },
  }
};

export const mixinAcceso = {
  data() {
    return {
      formularioActivo: false,
      mensajesFormulario: {
        'iniciando-sesion': {
          estado: false,
          type: "success",
          text: "Credenciales correctas! Iniciando Sesión...",
        },
        'credenciales-incorrectas': {
          estado: false,
          type: "error",
          text: "Las credenciales ingresadas son incorrectas. Por favor inténtalo nuevamente.",
        },
        'usuario-existe': {
          estado: false,
          type: "error",
          text: "El usuario que estás intentando crear ya existe. Cambia los datos e inténtalo nuevamente.",
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'isUserLoggedIn',
      'datosUsuario',
      'has_datosUsuario',
      'verificandoSesion',
    ]),
  },
  watch: {

  },
  methods: {
    ...mapMutations([
      'activarSesion',
      'updateUserData',
      'cerrarSesion',
      'si_verificandoSesion',
      'no_verificandoSesion',
    ]),
    iniciarSesion({ data }) {

      this.formularioValido = false;
      let parametros = {
        endpoint: 'access/sesion.iniciar-sesion',
        ...data
      }
      var formData = new FormData();
      for (var key in parametros) {
        formData.append(key, parametros[key])
      }

      this.axios.post('', formData).then((response) => {
        if (response.data == false) {
          this.mensajesFormulario['credenciales-incorrectas'].estado = true;
          setTimeout(() => {
            this.formularioValido = true;
            this.mensajesFormulario['credenciales-incorrectas'].estado = false;
          }, 4000);
        } else {

          localStorage.token = response.data;
          localStorage.loggedIn = 1;

          this.mensajesFormulario['iniciando-sesion'].estado = true;
          this.$store.commit('activarSesion', { data })
          this.$store.commit('updateUserData', { data })
          this.$router.push('/')
          this.actualizarDatosUsuario();

        }
      })


      // this.$router.push('/')
    },
    verificarSesion() {
      if (localStorage.token) {
        this.si_verificandoSesion()

        let parametros = {
          endpoint: 'access/token.validar-token',
          token: localStorage.token
        }

        var formData = new FormData();
        for (var key in parametros) {
          formData.append(key, parametros[key])
        }

        this.axios.post('', formData)
          .then((response) => {

            if(response.data == false){
              this.cerrarSesion()
            }
            if(response.data !== false){
              this.$store.commit('updateUserData', { data: true })
              this.$store.commit('activarSesion', { data: true })
  
              localStorage.loggedIn = 1;
  
              this.actualizarDatosUsuario();
              this.no_verificandoSesion();
            }

          })
          .catch(function () {
            localStorage.loggedIn = 0;
          });
      } else {
        // ? Si no hay token el estado de sesión automáticamente es falso
        localStorage.loggedIn = 0;
      }
    },
    registro({ data }) {
      console.log(data)


      this.formularioValido = false;
      let parametros = {
        endpoint: 'access/cuenta.registro',
        ...data
      }
      var formData = new FormData();
      for (var key in parametros) {
        formData.append(key, parametros[key])
      }

      this.axios.post('', formData).then((response) => {
        if (response.data == false) {
          this.mensajesFormulario['usuario-existe'].estado = true;
          setTimeout(() => {
            this.formularioValido = true;
            this.mensajesFormulario['usuario-existe'].estado = false;
          }, 4000);
        } else {

          console.log(response);
          localStorage.token = response.data;
          localStorage.loggedIn = 1;

          this.mensajesFormulario['iniciando-sesion'].estado = true;
          this.$store.commit('activarSesion', { data })
          this.$store.commit('updateUserData', { data })
          this.$router.push('/')
          this.actualizarDatosUsuario();

        }
      })


      // this.$store.commit('iniciarSesion', {data})
      // this.$store.commit('updateUserData', {data})
      // this.$router.push('/')
    },
    updateUserData(data) {
      this.$store.commit('updateUserData', { data })
    },
    cerrarSesion(data = true) {
      this.$store.commit('cerrarSesion', { data: data })
      localStorage.removeItem('token');
      localStorage.loggedIn = 0;
      this.$router.push('/')
      this.neutralizarTest()
    },
    actualizarDatosUsuario() {
      if (localStorage.token && localStorage.loggedIn == 1) {

        this.axios.get('', {
          params: {
            endpoint: 'user/single.datos-usuario',
            token: localStorage.token
          }
        })
          .then(response => {
            this.$store.commit('updateUserData', { data: response.data })
            this.$store.commit('activarUserData')
          })
          .catch(() => {

          })

      }
    }
  },
};
<template lang="">
    <div>
        <!-- Redes sociales y Wsp -->
        <rrss-wsp></rrss-wsp>
        <!-- Redes sociales y Wsp -->
        <div class="blue darken-4 white--text py-5">
            <v-container dark>
                <div class="d-flex align-start">
                    <a href="https://www.udec.cl/pexterno/" target="_blank">
                        <v-img class="d-inline-block" src="/marca/udecwhite.png" height="40px" position="left center" max-width="150px" contain></v-img>
                    </a>
                    <a href="https://fi.udec.cl/" target="_blank">
                        <v-img class="d-inline-block" src="/marca/fiwhite.png" height="40px" position="left center" max-width="150px" contain></v-img>
                    </a>
                </div>
                <v-divider color="white" class="my-4"></v-divider>
                <div class="d-flex justify-space-between">
                    <div class="flex-grow-1">
                        <div class="text-caption">Teléfono <span class="blue-grey--text text--lighten-4">decanatura: 41 220 4307</span></div>
                        <div class="text-caption">Correo: <span class="blue-grey--text text--lighten-4">comunicacionfi@udec.cl</span></div>
                        <div class="text-caption">Dirección: <span class="blue-grey--text text--lighten-4">Edmundo Larenas 219, Concepción, Chile</span></div>
                        <div class="text-caption">Visitanos en: <span class="blue-grey--text text--lighten-4">www.fi.udec.cl</span></div>
                    </div>
                    <div>
                        <a href="http://www.udec.cl/pexterno/node/152" target="_blank">
                            <v-img src="/marca/acreditacionwhite.png" height="60px" max-width="300px" position="right" contain></v-img>
                        </a>
                    </div>
                </div>
            </v-container>
        </div>
    </div>
</template>
<script>
import RrssWsp from "../info/RrssWsp.vue";
export default {
  components: { RrssWsp },
};
</script>
<style lang="scss">
</style>
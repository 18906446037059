import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/404.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/actividades-y-eventos',
    name: 'Actividades y eventos',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/ActividadesYEventos.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/comunidad-fi',
    name: 'Comunidad FI',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/ComunidadFi.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/explorador-vocacion',
    name: 'Explorador de Vocación',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/ExploradorVocacion.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/explora-tu-vocacion',
    name: 'Explora tu vocación',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/ExploraTuVocacion.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/mundo-ingenieria',
    name: 'Mundo Ingeniería',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/MundoIngenieria.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/test-vocacional',
    name: 'Test Vocacional',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/TestVocacional.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/cursos-y-talleres',
    name: 'Cursos y talleres',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/CursosYTalleres.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/registro',
    name: 'Registro',
    component: () => import(/* webpackChunkName: "about" */ '../views/acceso/Registro.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/iniciar-sesion',
    name: 'Iniciar Sesión',
    component: () => import(/* webpackChunkName: "about" */ '../views/acceso/IniciarSesion.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: () => import(/* webpackChunkName: "about" */ '../views/usuario/Perfil.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/editar-perfil',
    name: 'Editar perfil',
    component: () => import(/* webpackChunkName: "about" */ '../views/usuario/EditarPerfil.vue'),
    meta: { conditionalRoute: true }
  },
  {
    path: '/resultado-test-vocacional',
    name: 'Resultado test vocacional',
    component: () => import(/* webpackChunkName: "about" */ '../views/usuario/ResultadoTestVocacional.vue'),
    meta: { conditionalRoute: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import { mapGetters, mapMutations } from "vuex";
export const storeFI = {
  state: {
    // ? has_estadoTestVocacional: sí|no terminó de consultar el estado de su test vocacional
    has_estadoTestVocacional: false,
    // ? has_resultadoTestVocacional: sí|no tiene un test vocacional
    has_resultadoTestVocacional: false,
    // ? has_resultadoLoadedTestVocacional: sí|no tiene el resultado cargado en el front-end
    has_resultadoLoadedTestVocacional: false,
    consultando_test: false,
    puntajeMaximoTestVocacional: 24,
    // ? resultadoTestVocacional: {} resultado del test vocacional
    resultadoTestVocacional: {

    },
    hobbies: [
      { label: "Deportes colectivos" },
      { label: "Trekking" },
      { label: "Carpintería" },
      { label: "Yoga" },
      { label: "Gastronomía" },
      { label: "Artes Marciales" },
      { label: "Ajedrez" },
      { label: "Cine" },
      { label: "Tecnología" },
      { label: "Moda" },
      { label: "Streamings" },
      { label: "Podcasts" },
      { label: "Activismo" },
      { label: "Cultura" },
      { label: "Música" },
      { label: "Anime" },
      { label: "Mindfulness" },
      { label: "Mascotas" },
      { label: "Lectura" },
      { label: "Mundo tuerca" },
      { label: "Escritura" },
      { label: "Sustentabilidad" },
      { label: "Documentales" },
      { label: "Futurismo" },
      { label: "Danza" },
      { label: "Fitness" },
      { label: "Fotografía" },
      { label: "Mitología" },
      { label: "Idiomas" },
      { label: "Debate" },
      { label: "Gadgets" },
      { label: "Artes plásticas" },
      { label: "Deportes extremos" },
      { label: "Espiritualidad" },
      { label: "Artes Visuales" },
      { label: "Reality shows" },
      { label: "Cocina" },
      { label: "Emprendimiento" },
      { label: "Ciencias" },
      { label: "Atletismo" },
      { label: "Competiciones y torneos" },
      { label: "Espionaje" },
      { label: "Astronomía" },
      { label: "Montañismo" },
      { label: "Manualidades" },
      { label: "Trabajo social" },
      { label: "Política" },
      { label: "Videojuegos" },
      { label: "Juegos de mesa" },
      { label: "Medio Ambiente" },
      { label: "Colecciones" },
      { label: "Jardinería" },
      { label: "Fantasía" },
      { label: "Reciclaje" },
      { label: "Voluntariados" },
      { label: "Maquillaje" },
      { label: "Viajes" },
      { label: "Ciclismo" },
    ],
    intereses: [
      { label: "Algoritmos" },
      { label: "Asistentes virtuales" },
      { label: "Automatización de procesos" },
      { label: "Bots" },
      { label: "Inteligencia Artificial" },
      { label: "Redes Neuronales" },
      { label: "Almacenamiento de datos" },
      { label: "Interfaces de Programación" },
      { label: "Programación" },
      { label: "Smart Cities (Ciudades Inteligentes)" },
      { label: "Redes Computacionales" },
      { label: "Genética" },
      { label: "Impresión 3D" },
      { label: "Mapeo Cerebral" },
      { label: "Materiales Inteligentes" },
      { label: "Medicina Personalizada" },
      { label: "Nanotecnología" },
      { label: "Recursos Hídricos" },
      { label: "Superalimentos" },
      { label: "Espacios Inteligentes" },
      { label: "Integración de Dispositivos al Cuerpo Humano" },
      { label: "Inteligencia Aumentada" },
      { label: "Blockchain" },
      { label: "Ciberseguridad" },
      { label: "Encriptación de Datos" },
      { label: "Sistemas Biométricos" },
      { label: "Web Profunda (Deep Web)" },
      { label: "Cambio Climático" },
      { label: "Gamificación" },
      { label: "Identidad Digital" },
      { label: "Marketing Digital" },
      { label: "Viajes al Espacio" },
      { label: "Internet de las Cosas" },
      { label: "Dispositivos Portátiles" },
      { label: "Electromovilidad" },
      { label: "Sensorización" },
      { label: "Transportes Ecoamigables" },
      { label: "Vehículos Autónomos" },
      { label: "Realidad Aumentada" },
      { label: "Realidad Mixta" },
      { label: "Realidad Virtual" },
      { label: "Renderización Realista" },
      { label: "Robótica" },
      { label: "Videojuegos" },
    ],
    carreras: [
      {
        title: "Plan común",
        icon: "book-heart",
        carrera: "Plan común",
        puntaje: 0,
        empleabilidad: false,
        video: "j0M7YgA2mV0",
        icono: "iconoplancomun.png",
        color: "#19AF9A",
        desc: `
        Ingeniería Civil Plan Común de la FI UdeC te permite postular a una de las siguientes ocho especialidades de Ingeniería Civil: Eléctrica, Electrónica, Industrial, Informática, de Materiales, Mecánica, Metalúrgica y Química.
        `,
      },
      {
        title: "Civil",
        carrera: "Ingeniería Civil",
        puntaje: 0,
        empleabilidad: 93.4,
        icon: "file-chart-line",
        video: "zlEiZkCdeB4",
        icono: "iconocivil.png",
        color: "#D0394A",
        admision: "https://admision.udec.cl/ingenieria-civil/",
        desc: `
        Formamos profesionales altamente competentes y éticamente responsables, capaces de dirigir, desarrollar y elaborar proyectos complejos de ingeniería. Integramos diversas dimensiones como geotecnia, diseño de estructuras, construcción, hidráulica, sanitaria, vialidad y transporte.
        `,
      },
      {
        title: "Industrial",
        carrera: "Ingeniería Civil Industrial",
        puntaje: 0,
        empleabilidad: 94,
        icon: "industry-alt",
        video: "h2IbS4AO-ps",
        icono: "iconoindustrial.png",
        color: "#6668CD",
        admision: "https://admision.udec.cl/ingenieria-civil-industrial/",
        desc: `
        Entregamos una formación interdisciplinaria en computación e informática, investigación de operaciones y ciencias de la ingeniería. Sólidos conocimientos en ingeniería económica, gestión y producción para abordar problemas complejos de la ingeniería industrial y de sistemas, considerando siempre la variable medioambiental.
        `,
      },
      {
        title: "Biomédica",
        carrera: "Ingeniería Civil Biomédica",
        puntaje: 'N/A',
        empleabilidad: 0,
        icon: "monitor-heart-rate",
        video: "S-_WDhz3LWM",
        icono: "iconobiomedica.png",
        color: "#2FAB65",
        admision: "https://admision.udec.cl/ingenieria-civil-biomedica/",
        desc: `
        Apuntamos a la formación de profesionales con profundo conocimiento de la ingeniería y la medicina, capaces de desarrollar e investigar sistemas de diagnóstico, cirugía, dispositivos de asistencia fisiológica, tratamientos y equipamientos, tanto eléctrico como electrónico biomédico. 
        `,
      },
      {
        title: "Minas",
        carrera: "Ingeniería Civil de Minas",
        puntaje: 0,
        empleabilidad: 83,
        icon: "diamond",
        video: "lggt2kiSDYk",
        icono: "iconominas.png",
        color: "#9F9809",
        admision: "https://admision.udec.cl/ingenieria-civil-de-minas/",
        desc: `
        Formamos profesionales con bases sólidas en ciencias básicas e ingeniería, con una robusta formación técnica, flexibilidad y capacidad para trabajar en entornos multidisciplinarios y multiculturales, preparados para enfrentar los constantes cambios de la actividad minera, aplicando estándares de calidad y cuidado por el medio ambiente.
        `,
      },
      {
        title: "Eléctrica",
        carrera: "Ingeniería Civil Eléctrica",
        puntaje: 0,
        empleabilidad: 86,
        icon: "charging-station",
        video: "A1nCrfoe1o4",
        icono: "iconoelectrica.png",
        color: "#F7E500",
        admision: "https://admision.udec.cl/ingenieria-civil-electrica/",
        desc: `
        Preparamos profesionales para planificar y controlar sistemas electroenergéticos; concebir y diseñar equipos electromagnéticos. Entregamos las herramientas para abordar problemáticas de empresas privadas o públicas, tanto de servicios como productivas. Promovemos además la capacidad de innovación y creación de empresas propias.
        `,
      },
      {
        title: "Metalúrgica",
        carrera: "Ingeniería Civil Metalúrgica",
        puntaje: 0,
        empleabilidad: 83,
        icon: "cubes",
        video: "JRd6aComMuI",
        icono: "iconometalurgica.png",
        color: "#C38039",
        admision: "",
        desc: `
        Formados profesionales para enfrentar un mundo cambiante, caracterizado por una mineralogía cada día más compleja, la disminución de la ley de los minerales y regulaciones medioambientales avanzadas. Otorgamos especialización en metalurgia extractiva que los capacita para ser agentes innovadores y creativos al interior de las empresas.
        `,
      },
      {
        title: "Química",
        carrera: "Ingeniería Civil Quimica",
        puntaje: 0,
        empleabilidad: 91,
        icon: "atom-alt",
        video: "l_N0WdK3V50",
        icono: "iconoquimica.png",
        color: "#E61C72",
        admision: "https://admision.udec.cl/ingenieria-civil-metalurgica/",
        desc: `
          Formamos profesionales altamente competentes para tomar decisiones que optimicen el diseño y funcionamiento de procesos operacionales y de gestión. Que se desenvuelvan con autonomía e iniciativa propia, siendo capaces de liderar equipos multidisciplinarios y de adaptarse rápidamente para impulsar cambios en todas las áreas.
        `,
      },
      {
        title: "Materiales",
        carrera: "Ingeniería Civil de Materiales",
        puntaje: 0,
        empleabilidad: 74,
        icon: "dice-d8",
        video: "tDT3jzaXkNw",
        icono: "iconomateriales.png",
        color: "#E61C72",
        admision: "https://admision.udec.cl/ingenieria-civil-de-materiales/",
        desc: `
        Formamos profesionales con alta preparación en el campo de la metalurgia, los materiales y las tecnologías emergentes, capaces de gestionar todas las etapas de creación y operación de industrias metalúrgicas y procesadoras de materiales. Lo anterior, en armonía con el medio ambiente, pudiendo además seleccionar y adquirir nuevos materiales y aleaciones.
        `,
      },
      {
        title: "Telecomunicaciones",
        carrera: "Ingeniería Civil en Telecomunicaciones",
        puntaje: 0,
        empleabilidad: 94,
        icon: "satellite",
        video: "uV02QZFlfVY",
        icono: "iconotelecomunicaciones.png",
        color: "#DAED33",
        admision: "https://admision.udec.cl/ingenieria-civil-en-telecomunicaciones/",
        desc: `
        Formamos profesionales capaces de solucionar problemáticas, tanto del área de las tecnologías de la información, como para servicios y aplicaciones científicas e industriales. Les entregamos herramientas para concebir, diseñar, implementar y operar sistemas; redes y servicios de generación, transmisión, detección, manejo y gestión de la información.
        `,
      },
      {
        title: "Electrónica",
        carrera: "Ingeniería Civil Electrónica",
        puntaje: 0,
        empleabilidad: 86,
        icon: "drone-alt",
        video: "k5HzYPqfVUE",
        icono: "iconoelectronica.png",
        color: "#E84E1A",
        admision: "https://admision.udec.cl/ingenieria-civil-electronica/",
        desc: `
        Nuestros profesionales son capaces de aplicar los conocimientos de las ciencias básicas y las tecnologías para gestionar bienes y servicios. Entregamos sólidos conocimientos en electrónica de potencia, automatización, sistemas digitales y computación.
        `,
      },
      {
        title: "Mecánica",
        carrera: "Ingeniería Civil Mecánica",
        puntaje: 0,
        empleabilidad: 90,
        icon: "cogs",
        video: "z9hvkxX_BJE",
        icono: "iconomecanica.png",
        color: "#005EE2",
        admision: "https://admision.udec.cl/ingenieria-civil-mecanica/",
        desc: `
        Entregamos las herramientas necesarias para solucionar problemas complejos, además de desarrollar investigaciones y estudios detallados. Potenciamos la formación de un liderazgo proactivo en equipos multidisciplinarios, como también la comunicación efectiva y el inglés como segundo idioma.
        `,
      },
      {
        title: "Informática",
        carrera: "Ingeniería Civil Informática",
        puntaje: 0,
        empleabilidad: 93,
        icon: "th",
        video: "3lf28_vgwtc",
        icono: "iconoinformatica.png",
        color: "#28235D",
        admision: "https://admision.udec.cl/ingenieria-civil-informatica/",
        desc: `
        Preparamos profesionales para identificar, abordar y solucionar problemas que requieren procesamiento automático de información, aplicando principios de matemáticas, ciencias de la ingeniería y computación. Entregamos conocimientos para reconocer necesidades de usuarios, analizar e interpretar grandes volúmenes de datos, además de diseñar experimentos para evaluar, caracterizar y modelar sistemas informáticos complejos.
        `,
      },
      {
        title: "Aeroespacial",
        carrera: "Ingeniería Civil Aeroespacial",
        puntaje: 0,
        empleabilidad: 83,
        icon: "solar-system",
        video: "wYUtnbFr7mg",
        icono: "iconoaeroespacial.png",
        color: "#009DE0",
        admision: "https://admision.udec.cl/ingenieria-civil-aeroespacial/",
        desc: `
        Formamos profesionales capaces de concebir, diseñar, implementar y operar sistemas, productos y procesos relacionados con la tecnología aeronáutica y espacial. Entregamos herramientas para solucionar problemas complejos en el ámbito de la aeronáutica y el espacio, así como desarrollar investigaciones en el área.
        `,
      },
    ],
  },
  getters: {
    hobbies(state) {
      return state.hobbies
    },
    intereses(state) {
      return state.intereses
    },
    carreras(state) {
      return state.carreras
    },
    has_resultadoTestVocacional(state) {
      return state.has_resultadoTestVocacional
    },
    has_resultadoLoadedTestVocacional(state) {
      return state.has_resultadoLoadedTestVocacional
    },
    has_estadoTestVocacional(state) {
      return state.has_estadoTestVocacional
    },
    resultadoTestVocacional(state) {
      return state.resultadoTestVocacional
    },
    consultando_test(state) {
      return state.consultando_test
    },

  },
  mutations: {
    consultado_testVocacional(state) {
      state.has_estadoTestVocacional = true;
    },
    no_consultado_testVocacional(state) {
      state.has_estadoTestVocacional = false;
    },
    si_testVocacional(state) {
      state.has_resultadoTestVocacional = true;
    },
    no_testVocacional(state) {
      state.has_resultadoTestVocacional = false;
    },
    si_testVocacionalLoaded(state) {
      state.has_resultadoLoadedTestVocacional = true;
    },
    no_testVocacionalLoaded(state) {
      state.has_resultadoLoadedTestVocacional = false;
    },
    post_resultadoTestVocacional(state, { data }) {
      state.resultadoTestVocacional = data;
    },
    si_consultando_test(state) {
      state.consultando_test = true;
    },
    no_consultando_test(state) {
      state.consultando_test = false;
    },
  },
  actions: {

  },
  modules: {

  }
}

export const mixinFI = {
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'hobbies',
      'intereses',
      'carreras',
      'has_resultadoTestVocacional',
      'has_estadoTestVocacional',
      'resultadoTestVocacional',
      'has_resultadoLoadedTestVocacional',
      'consultando_test'
    ]),
    hobbiesUsuario() {
      let res = []
      if (this.has_datosUsuario && typeof (this.datosUsuario.data.meta['wpcf-intereses-hobbies']) !== 'undefined') {


        res = this.hobbies.filter((el, index) => {
          let indexStr = index.toString();
          return this.datosUsuario.data.meta['wpcf-intereses-hobbies'].includes(indexStr);
        });

      }

      return res

    },
    interesesUsuario() {
      let res = []


      if (this.has_datosUsuario && typeof (this.datosUsuario.data.meta['wpcf-intereses-tecnologicos']) !== 'undefined') {
        res = this.intereses.filter((el, index) => {
          let indexStr = index.toString();
          return this.datosUsuario.data.meta['wpcf-intereses-tecnologicos'].includes(indexStr);
        });
      }

      return res
    },

    resultadoCarreras() {

      let carreras = this.carreras

      if (this.has_resultadoLoadedTestVocacional) {

        let resultadoTest = this.resultadoTestVocacional
        let keysRes = Object.keys(resultadoTest)
        // console.log(resultadoTest)
        // console.log(keysRes)

        keysRes.forEach((keyRes) => {

          carreras.forEach((carrera, keyCarrera) => {
            if (carrera.carrera == keyRes) {
              carreras[keyCarrera].puntaje = resultadoTest[keyRes].puntaje
            }
          })

        })
      }


      carreras = this.carreras.filter(el => el.title !== 'Plan común')
      carreras.sort((a, b) => (a.puntaje < b.puntaje) ? 1 : ((b.puntaje < a.puntaje) ? -1 : 0))

      let res = {
        data: carreras,
        labels: carreras.map(el => el.title),
        puntajes: carreras.map(el => el.puntaje),
        pors: carreras.map(el => el.puntaje / 24),
      }

      return res;
    }
  },
  methods: {
    ...mapMutations([
      'consultado_testVocacional',
      'no_consultado_testVocacional',
      'si_testVocacional',
      'no_testVocacional',
      'si_testVocacionalLoaded',
      'no_testVocacionalLoaded',
      'post_resultadoTestVocacional',
      'si_consultando_test',
      'no_consultando_test',
    ]),
    neutralizarTest() {
      this.$store.commit('no_testVocacional')
      this.$store.commit('no_testVocacionalLoaded')
      this.$store.commit('no_consultado_testVocacional')
      this.$store.commit('no_consultando_test')
    },
    consultarEstadoTest() {
      if (localStorage.token && this.isUserLoggedIn && this.has_datosUsuario) {
        this.axios.get('', {
          params: {
            endpoint: 'cliente/udec.has_respuestaTestVocacional',
            id: this.datosUsuario.data['ID'],
            token: localStorage.token
          }
        })
          .then(response => {
            if (response.data == true) {
              this.$store.commit('si_testVocacional')
              this.$store.commit('consultado_testVocacional')
            }
            if (response.data == false) {
              this.$store.commit('no_testVocacional')
              this.$store.commit('consultado_testVocacional')
            }
            // this.$store.commit('updateUserData', { data: response.data })
            // this.$store.commit('activarUserData')
          })
          .catch(() => {

          })
      }
    },
    consultarRespuestaTest() {
      if (localStorage.token && this.isUserLoggedIn && this.has_datosUsuario) {


        this.$store.commit('no_consultado_testVocacional')
        this.$store.commit('si_consultando_test')


        this.axios.get('', {
          params: {
            endpoint: 'cliente/udec.resultado_usuario',
            id: this.datosUsuario.data['ID'],
            token: localStorage.token
          }
        })
          .then(response => {
            if (response.data == false) {
              this.$store.commit('consultado_testVocacional')
              this.$store.commit('no_testVocacional')
              this.$store.commit('no_testVocacionalLoaded')
            } else {
              this.$store.commit('consultado_testVocacional')
              this.$store.commit('post_resultadoTestVocacional', { data: response.data })
              this.$store.commit('si_testVocacional')
              this.$store.commit('si_testVocacionalLoaded')
            }

            this.$store.commit('no_consultando_test')



            // this.$store.commit('updateUserData', { data: response.data })
            // this.$store.commit('activarUserData')
          })
          .catch(() => {

          })
      }
    }

  }
}
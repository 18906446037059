import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  // G-5SBPNW9C5F
  id: 'G-5SBPNW9C5F'
})





// ? Tippy |INICIO|
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  }
});
Vue.component("tippy", TippyComponent);
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";










// ? Axios: solicitudes http
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
let domain = (new URL(window.location.href));
const baseURL = (domain.hostname == 'localhost') ? 'http://apiexperienciaingenieria:8888/api/' : 'http://apiexperienciaingenieria.uinn.cl/api/';
if (typeof baseURL !== 'undefined') {
  Vue.axios.defaults.baseURL = baseURL;
}



import vueAwesomeCountdown from 'vue-awesome-countdown'
Vue.use(vueAwesomeCountdown, 'vac')


// * Configuraciones de íconos
import './config/_fontAwesome'
// * Carga de stores y mixins
import './config/_stores'
// * Importar estilos personalizados
import './scss/global.scss';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
